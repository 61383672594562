import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@/providers/Auth'
import Sidebar from '@/ui/molecules/Sidebar'
import Header from '@/ui/organisms/Header'
import HeaderEnvelope from '@/ui/organisms/HeaderEnvelope'
import HeaderEnvelopeV2 from '@/ui/organisms/HeaderEnvelopeV2'
import HeaderTemplate from '@/ui/organisms/HeaderTemplate'

type TemplateType = 'default' | 'envelope' | 'envelopeNew' | 'template'

interface LoggedInAreaTemplateProps {
  children: React.ReactNode
  templateType?: TemplateType
}

const LoggedInAreaTemplate: React.FC<LoggedInAreaTemplateProps> = ({
  children,
  templateType = 'default'
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isComponentReady, setIsComponentReady] = useState(false)

  const { back, pathname, push, isReady } = useRouter()
  const redirectedRef = useRef(false)

  const { userContext, isLogged, refreshTokenWithValidator, isAdmin } =
    useAuth()

  useEffect(() => {
    setIsComponentReady(true)
  }, [])

  useEffect(() => {
    refreshTokenWithValidator()
  }, [refreshTokenWithValidator])

  useEffect(() => {
    if (isComponentReady && !redirectedRef.current && !isLogged) {
      push('/logout')
      redirectedRef.current = true
    }
    if (pathname === '/login' && isLogged) {
      push('/')
    }

    const shouldClearLocalStorage = ['/envelope', '/template', '/vault'].every(
      (subPath) => !pathname.includes(subPath)
    )
    if (isReady && shouldClearLocalStorage) {
      window.localStorage.removeItem('currentVaultId')
    }
  }, [
    isComponentReady,
    isLogged,
    userContext,
    isAdmin,
    pathname,
    isReady,
    push
  ])

  if (!isComponentReady || !isLogged) {
    return null
  }

  const handleGoBack = () => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('currentVaultId')
    }
    back()
  }

  return (
    <div className='flex flex-col min-h-screen"'>
      {templateType === 'default' && (
        <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      )}
      {templateType === 'envelope' && (
        <HeaderEnvelope mode="createOrEdit" onGoBack={handleGoBack} />
      )}
      {templateType === 'envelopeNew' && (
        <HeaderEnvelopeV2 mode="createOrEdit" />
      )}
      {templateType === 'template' && <HeaderTemplate />}
      {templateType === 'default' && (
        <Header setSidebarOpen={setIsSidebarOpen} />
      )}
      <main className="flex-grow">{children}</main>
    </div>
  )
}

export default LoggedInAreaTemplate
