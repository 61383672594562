import { Editor } from '@tiptap/react'
import { FaHighlighter } from 'react-icons/fa'
import {
  MdFormatQuote,
  MdOutlineCode,
  MdOutlineFormatAlignCenter,
  MdOutlineFormatAlignJustify,
  MdOutlineFormatAlignLeft,
  MdOutlineFormatAlignRight,
  MdOutlineFormatBold,
  MdOutlineFormatClear,
  MdOutlineFormatColorText,
  MdOutlineFormatItalic,
  MdOutlineFormatListBulleted,
  MdOutlineFormatListNumbered,
  MdOutlineFormatStrikethrough,
  MdOutlineImage,
  MdOutlineInsertLink,
  MdOutlineRedo,
  MdOutlineTextFormat,
  MdOutlineUndo
} from 'react-icons/md'

export type MenuBarItem = {
  icon: JSX.Element
  id: string
  type?: string
  action: (options: any) => void
  isActive?: () => boolean
}

export const menuBarItems = (editor: Editor) => {
  if (!editor) return []
  return [
    {
      icon: <MdOutlineFormatBold className="text-sig-black" />,
      id: 'bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold')
    },
    {
      icon: <MdOutlineFormatItalic className="text-sig-black" />,
      id: 'italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic')
    },
    {
      icon: <MdOutlineTextFormat className="text-sig-black" />,
      id: 'underline',
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline')
    },
    {
      icon: <MdOutlineFormatStrikethrough className="text-sig-black" />,
      id: 'strike',
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike')
    },
    {
      icon: <FaHighlighter className="text-sig-black" />,
      id: 'highlight',
      type: 'colorPicker',
      action: ({ color }: { color: string }) =>
        editor.chain().focus().setHighlight({ color }).run(),
      isActive: () => editor.isActive('highlight')
    },
    {
      icon: <MdOutlineFormatColorText className="text-sig-black" />,
      id: 'color',
      type: 'colorPicker',
      action: ({ color }: { color: string }) =>
        editor.chain().focus().setColor(color).run(),
      isActive: () => editor.isActive('color')
    },
    {
      type: 'divider'
    },
    {
      icon: <MdOutlineFormatListNumbered className="text-sig-black" />,
      id: 'ordered-list',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList')
    },
    {
      icon: <MdOutlineFormatListBulleted className="text-sig-black" />,
      id: 'bullet-list',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList')
    },
    {
      icon: <MdOutlineFormatAlignLeft className="text-sig-black" />,
      id: 'align-left',
      action: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.isActive({ textAlign: 'left' })
    },
    {
      icon: <MdOutlineFormatAlignCenter className="text-sig-black" />,
      id: 'align-center',
      action: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.isActive({ textAlign: 'center' })
    },
    {
      icon: <MdOutlineFormatAlignRight className="text-sig-black" />,
      id: 'align-right',
      action: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.isActive({ textAlign: 'right' })
    },
    {
      icon: <MdOutlineFormatAlignJustify className="text-sig-black" />,
      id: 'align-justify',
      action: () => editor.chain().focus().setTextAlign('justify').run(),
      isActive: () => editor.isActive({ textAlign: 'justify' })
    },
    {
      type: 'divider'
    },
    {
      icon: <MdFormatQuote className="text-sig-black" />,
      id: 'blockquote',
      action: () => editor.chain().focus().toggleBlockquote().run(),
      isActive: () => editor.isActive('blockquote')
    },
    {
      icon: <MdOutlineFormatClear className="text-sig-black" />,
      id: 'clear-format',
      action: () => editor.chain().focus().clearNodes().unsetAllMarks().run()
    },
    {
      icon: <MdOutlineCode className="text-sig-black" />,
      id: 'code-block',
      action: () => editor.chain().focus().toggleCodeBlock().run(),
      isActive: () => editor.isActive('codeBlock')
    },
    {
      icon: <MdOutlineInsertLink className="text-sig-black" />,
      id: 'link',
      type: 'link',
      action: ({ href }: { href: string }) => {
        if (href === null) {
          return
        }

        if (href === '') {
          editor.chain().focus().extendMarkRange('link').unsetLink().run()

          return
        }

        return editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href })
          .run()
      },
      isActive: () => editor.isActive('link')
    },
    {
      icon: <MdOutlineImage className="text-sig-black" />,
      id: 'image',
      type: 'image',
      action: ({ src }: { src: string }) => {
        if (src === null) {
          return
        }

        return editor.chain().focus().setImage({ src }).run()
      }
    },
    {
      type: 'divider'
    },
    {
      icon: <MdOutlineUndo className="text-sig-black" />,
      id: 'undo',
      action: () => editor.chain().focus().undo().run()
    },
    {
      icon: <MdOutlineRedo className="text-sig-black" />,
      id: 'redo',
      action: () => editor.chain().focus().redo().run()
    }
  ] as MenuBarItem[]
}
