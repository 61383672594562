import React, { useMemo } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { CgSpinner } from 'react-icons/cg'
import { useViewport } from '@/hooks/useViewport'
import { type StepType } from '@/hooks/useSteps/useSteps'
import { cn } from '@/lib/utils'
import Typography from '@/ui/atoms/Typography'

type StepsProps = {
  steps: StepType[]
  activeStep: string
  goToStep?: (name: string) => Promise<void> | void
  classNameContainer?: string
  showSkeleton?: boolean
  isLoadingStep?: {
    isLoading: boolean
    step: string | null
  }
}

const StepsV2: React.FC<StepsProps> = ({
  steps,
  activeStep,
  goToStep,
  classNameContainer,
  showSkeleton = false,
  isLoadingStep
}) => {
  const { breakpoint } = useViewport()

  const showOnlyActiveText = useMemo(() => {
    return breakpoint === 'xs'
  }, [breakpoint])

  // Testar cor laranja para o texto

  return (
    <div
      className={cn(
        'flex items-center justify-between py-2 container-step-envelope',
        classNameContainer
      )}
    >
      {steps?.map(({ id, name, label, description, isCompleted }) => {
        const isActive = name === activeStep
        const index = id + 1

        return (
          <div
            key={index}
            className={cn(
              'flex items-center gap-2 md:min-w-40',
              goToStep ? 'cursor-pointer' : ''
              // isCompleted ? 'opacity-80' : ''
            )}
            onClick={() => goToStep?.(name)}
          >
            <div
              className={cn(
                'w-10 h-10 flex items-center justify-center rounded-lg',
                isActive
                  ? 'bg-warning-500 text-white dark:text-black'
                  : 'bg-primary-100 dark:bg-primary-100 text-warning-500 dark:text-primary-700'
              )}
            >
              {/* <CgSpinner className="text-xl animate-spin" /> */}
              {isLoadingStep?.isLoading && isLoadingStep.step === name ? (
                <CgSpinner className="w-4 h-4 animate-spin" />
              ) : (
                index
              )}
            </div>
            {(!showOnlyActiveText || isActive) && (
              <div className={cn('flex flex-col', showSkeleton && 'gap-1')}>
                <Typography
                  variant="text-base-medium"
                  className="text-gray-700 dark:text-gray-700 text-[15px] hover:text-gray-800 font-display"
                  showSkeleton={showSkeleton}
                  classNameSkeleton="w-12"
                >
                  {label}
                </Typography>
                <Typography
                  variant="text-sm-regular"
                  className="text-gray-700 dark:text-gray-700 text-xs hover:text-gray-800"
                  showSkeleton={showSkeleton}
                  classNameSkeleton="w-20"
                >
                  {description}
                </Typography>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default StepsV2
