import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, useEditor } from '@tiptap/react'
import { useRouter } from 'next/router'
import useLocalStorage from '@/hooks/useLocalStorage'
import useTranslation from '@/hooks/useTranslation'
import {
  CurrentActionTemplateType,
  CurrentDataTemplate,
  DataDrawerActionFieldType,
  DataModalConfirmType,
  TemplateContextType,
  ValuesDataTemplateType
} from './Template.types'
import { ActionFieldType, Props } from './Template.types'
import useTemplateApi from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi'
import { FieldType } from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import { useVaultApi } from '@/hooks/api/ecm/useVaultApi'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault'
import { showToast } from '@/ui/atoms/Toast'
import { MenuBarItem, extensions, menuBarItems } from '@/utils/editor/config'

const store = createContext<TemplateContextType>({} as TemplateContextType)
const { Provider } = store

function TemplateContext({ children }: Props) {
  const { t } = useTranslation('template')
  const { push, back } = useRouter()
  const [currentVaultId, setCurrentVaultId, isLoadingCurrentVaultId] =
    useLocalStorage<CurrentVaultId>('currentVaultId', null)
  const [dataDrawerActionField, setDataDrawerActionField] =
    useState<DataDrawerActionFieldType>({
      open: false,
      action: ActionFieldType.CREATE,
      alias: null
    })
  const [openDrawerTemplateDetails, setOpenDrawerTemplateDetails] =
    useState(false)
  const [dataModalConfirm, setDataModalConfirm] =
    useState<DataModalConfirmType>({
      action: null,
      open: false,
      id: null
    })
  const [openModalSearchVault, setOpenModalSearchVault] = useState(false)
  const [currentDataTemplate, setCurrentDataTemplate] =
    useState<CurrentDataTemplate>({} as CurrentDataTemplate)
  const [currentActionTemplate, setCurrentActionTemplate] =
    useState<CurrentActionTemplateType>({
      id: null,
      action: null
    })
  const [editable, setEditable] = useState<boolean>(true)
  const { useGetVault, useCreateVault, useGetFirstVault } = useVaultApi()
  const { useCreateTemplate, useUpdateTemplate, useGetTemplate } =
    useTemplateApi()
  const { data: dataFirstVault, isFetched: isFetchedFirstVault } =
    useGetFirstVault(!isLoadingCurrentVaultId && !currentVaultId ? true : false)
  const {
    data: dataGetVault,
    isLoading: isLoadingGetVaultInternal,
    isError: isErrorGetVault,
    fetchStatus: fetchStatusGetVault
  } = useGetVault(!currentVaultId ? null : currentVaultId)
  const { mutateAsync: mutateAsyncCreateVault } = useCreateVault()
  const isLoadingGetVault =
    fetchStatusGetVault === 'fetching' && isLoadingGetVaultInternal
  const {
    mutateAsync: mutateAsyncCreateTemplate,
    isLoading: isLoadingCreateTemplate
  } = useCreateTemplate()
  const {
    mutateAsync: mutateAsyncUpdateTemplate,
    isLoading: isLoadingUpdateTemplate
  } = useUpdateTemplate()
  const {
    data: dataTemplate,
    isLoading: isLoadingDataTemplate,
    refetch: refetchDataTemplate,
    isError: isErrorDataTemplate
  } = useGetTemplate(
    currentActionTemplate?.id ? currentActionTemplate.id : null
  )

  const dataCurrentVault = useMemo(() => {
    if (isFetchedFirstVault) {
      setCurrentVaultId(dataFirstVault?.items[0]?.vaultId || '')
      return {
        value: dataFirstVault?.items[0]?.vaultId || '',
        label: dataFirstVault?.items[0]?.name || ''
      }
    }
    if (dataGetVault) {
      setCurrentVaultId(dataGetVault?.id)
      return {
        value: dataGetVault?.id || '',
        label: dataGetVault?.name || ''
      }
    }
    return null
  }, [dataFirstVault, dataGetVault, isFetchedFirstVault, setCurrentVaultId])

  // Função para criar um cofre inicial se não houver nenhum
  const createInitialVault = useCallback(async () => {
    const response = await mutateAsyncCreateVault({
      name: t?.defaultVaultName,
      type: 'UserAccount'
    })
    if (response?.vaultId) {
      setCurrentVaultId(response?.vaultId)
    }
  }, [mutateAsyncCreateVault, setCurrentVaultId, t?.defaultVaultName])

  // useEffect para verificar e criar o cofre inicial se necessário
  useEffect(() => {
    if (isFetchedFirstVault && dataFirstVault?.items?.length === 0) {
      createInitialVault()
    }
  }, [createInitialVault, dataFirstVault?.items?.length, isFetchedFirstVault])

  const editor = useEditor({
    editable,
    extensions: [
      ...extensions,
      Placeholder.configure({
        placeholder: t?.placeholderTypeHere || 'Digite aqui...'
      })
    ],
    content: '',
    onUpdate: ({ editor }) => {
      const htmlContent = editor?.getHTML()

      if (htmlContent !== currentDataTemplate?.payload) {
        setCurrentDataTemplate((prevTemplate) => ({
          ...prevTemplate,
          payload: htmlContent
        }))
      }
    }
  }) as Editor

  const items = useMemo(() => {
    if (!editor) return []
    const menuBarItemsWithName = menuBarItems(editor)?.map((item) => ({
      ...item,
      name: t?.editor?.[item.id] || item.id
    }))
    return menuBarItemsWithName
  }, [editor, t?.editor]) as MenuBarItem[]

  const handleInsertField = (value: string) => {
    if (!editor) return
    editor.chain().focus().setField(value).run()
  }

  const handleCloseDrawer = useCallback(() => {
    setDataDrawerActionField({ open: false, action: ActionFieldType.CREATE })
  }, [])

  const handleDeleteField = useCallback(async () => {
    const newFields = currentDataTemplate?.fields?.filter(
      (fieldFilter) => fieldFilter.alias !== dataModalConfirm?.id
    )
    const field = currentDataTemplate?.fields?.find(
      (fieldFilter) => fieldFilter.alias === dataModalConfirm?.id
    )
    setCurrentDataTemplate({
      ...currentDataTemplate,
      fields: newFields
    })
    if (!editor) return

    const editorContent = editor.getHTML()

    const regex = new RegExp(
      `<span data-field="true" class="field-template" alias="${field?.alias}">${field?.alias}</span>`,
      'g'
    )
    const newContent = editorContent.replace(regex, field?.name || '')

    editor.commands.setContent(newContent)
  }, [currentDataTemplate, dataModalConfirm?.id, editor])

  useEffect(() => {
    if (isErrorGetVault) {
      back()
    }
    if (isErrorDataTemplate && currentActionTemplate.action === 'edit') {
      push('/')
    }
    if (
      currentActionTemplate.action === 'edit' &&
      dataTemplate?.id &&
      currentDataTemplate?.id !== dataTemplate?.id
    ) {
      setCurrentDataTemplate(dataTemplate)
      setCurrentVaultId(dataTemplate?.vaultId)
      editor?.commands.setContent(dataTemplate.payload || '')
      editor?.commands.focus('start')
      return
    }
    if (editor) {
      editor.commands.focus('start')
      editor.setEditable(editable)
    }
  }, [
    back,
    currentActionTemplate.action,
    currentDataTemplate?.id,
    dataTemplate,
    editable,
    editor,
    isErrorDataTemplate,
    isErrorGetVault,
    push,
    setCurrentVaultId
  ])

  const handleButtonSubmitSave = useCallback(
    async (values: ValuesDataTemplateType) => {
      if (!values?.name) {
        showToast.error(t?.toasts?.errorNameTemplate)
        return
      }

      const vaultId = values?.vault?.value || ''
      if (currentDataTemplate?.id) {
        const fieldsUpdated = currentDataTemplate?.fields?.map((field) => {
          if (field.id?.includes('fake-id')) {
            return {
              ...field,
              id: undefined
            }
          }
          return field
        })

        try {
          const body = {
            id: currentDataTemplate?.id,
            vaultId: vaultId,
            name: values?.name || '',
            description: values?.description,
            payload: editor.getHTML(),
            fields: fieldsUpdated || []
          }
          await mutateAsyncUpdateTemplate(body)
          setCurrentDataTemplate(body)
          refetchDataTemplate()
          showToast.success(t?.toasts?.successUpdateTemplate)
        } catch {
          showToast.error(t?.toasts?.errorUpdateTemplate)
        } finally {
          push(`/vaults/${vaultId}`)
        }
        return
      }
      try {
        const data = await mutateAsyncCreateTemplate({
          vaultId: vaultId,
          name: values?.name || '',
          description: values?.description,
          payload: editor.getHTML(),
          fields: currentDataTemplate?.fields || []
        })
        setCurrentActionTemplate({
          action: 'create',
          id: data?.id || null
        })
        setCurrentDataTemplate({
          ...currentDataTemplate,
          id: data?.id
        })
        refetchDataTemplate()
        showToast.success(t?.toasts?.successCreateTemplate)
      } catch {
        showToast.error(t?.toasts?.errorCreateTemplate)
      } finally {
        push(`/vaults/${vaultId}`)
      }
    },
    [
      currentDataTemplate,
      editor,
      mutateAsyncCreateTemplate,
      mutateAsyncUpdateTemplate,
      push,
      refetchDataTemplate,
      t?.toasts?.errorCreateTemplate,
      t?.toasts?.errorNameTemplate,
      t?.toasts?.errorUpdateTemplate,
      t?.toasts?.successCreateTemplate,
      t?.toasts?.successUpdateTemplate
    ]
  )

  const replaceFieldAliasInEditorContent = (
    content: string,
    oldAlias: string,
    newAlias: string
  ): string => {
    const regex = new RegExp(
      `<span data-field="true" class="field-template" alias="${oldAlias}">${oldAlias}</span>`,
      'g'
    )
    return content.replace(
      regex,
      `<span data-field="true" class="field-template" alias="${newAlias}">${newAlias}</span>`
    )
  }

  const handleSaveField = useCallback(
    (field: FieldType, alias?: string) => {
      const updateFields = (
        fields: FieldType[] = [],
        newField: FieldType,
        oldAlias?: string
      ): FieldType[] => {
        const fieldExists = fields.some((field) => field.alias === oldAlias)
        return fieldExists
          ? fields.map((fieldMap) =>
              fieldMap.alias === oldAlias ? newField : fieldMap
            )
          : [...fields, newField]
      }

      const updatedFields = updateFields(
        currentDataTemplate?.fields as FieldType[],
        field,
        alias
      )
      setCurrentDataTemplate((prevTemplate) => ({
        ...prevTemplate,
        fields: updatedFields
      }))

      if (editor && alias) {
        const newEditorContent = replaceFieldAliasInEditorContent(
          editor.getHTML(),
          alias,
          field.alias
        )
        editor.commands.setContent(newEditorContent)
      }
    },
    [currentDataTemplate, editor]
  )

  const changesTemplate = useCallback((): {
    changes: Record<string, any>
    hasChanges: boolean
  } => {
    if (!currentDataTemplate?.name || !dataTemplate?.id) {
      return { changes: {}, hasChanges: false }
    }

    const changes: Record<string, any> = {
      fields: {}
    }

    const properties: (keyof CurrentDataTemplate)[] = [
      'vaultId',
      'name',
      'description',
      'payload'
    ]
    properties.forEach((prop) => {
      if (dataTemplate[prop] !== currentDataTemplate[prop]) {
        changes[prop] = {
          from: dataTemplate[prop],
          to: currentDataTemplate[prop]
        }
      }
    })

    dataTemplate.fields?.forEach((field) => {
      const currentField = currentDataTemplate.fields?.find(
        (f) => f.id === field.id
      )
      if (currentField) {
        const fieldProps: (keyof FieldType)[] = [
          'name',
          'alias',
          'isRequired',
          'type',
          'customRegex'
        ]
        fieldProps.forEach((prop) => {
          if (field[prop] !== currentField[prop]) {
            const fieldId = String(field.id)
            changes.fields[fieldId] = changes.fields[fieldId] || {
              id: fieldId,
              alias: field.alias,
              changes: []
            }
            changes.fields[fieldId].changes.push({
              property: prop,
              from: field[prop],
              to: currentField[prop]
            })
          }
        })
      }
    })

    const hasChangeInFields = Object.keys(changes.fields).length > 0
    const hasOtherChanges = Object.keys(changes).some(
      (key) => key !== 'fields' && changes[key]
    )
    const hasChanges = hasChangeInFields || hasOtherChanges

    return { changes, hasChanges }
  }, [currentDataTemplate, dataTemplate])

  const loadingEditor = useMemo(() => {
    return (
      (currentActionTemplate.action === 'edit' && isLoadingDataTemplate) ||
      !editor?.getHTML()
    )
  }, [currentActionTemplate.action, editor, isLoadingDataTemplate])

  const loadingSubmit = useMemo(() => {
    return isLoadingCreateTemplate || isLoadingUpdateTemplate
  }, [isLoadingCreateTemplate, isLoadingUpdateTemplate])

  const handleSearchVaultCallback = useCallback(
    (vault: { vaultId: string; vaultName: string }) => {
      if (
        vault.vaultId &&
        currentVaultId !== vault.vaultId &&
        setCurrentVaultId
      ) {
        setCurrentVaultId(vault?.vaultId)
      }
      setTimeout(() => {
        setOpenModalSearchVault(false)
      }, 800)
    },
    [currentVaultId, setCurrentVaultId]
  )

  return (
    <Provider
      value={{
        dataCurrentVault,
        editor,
        items,
        handleInsertField,
        dataDrawerActionField,
        setDataDrawerActionField,
        handleCloseDrawer,
        currentActionTemplate,
        setCurrentActionTemplate,
        currentDataTemplate,
        setCurrentDataTemplate,
        handleDeleteField,
        handleSaveField,
        currentVaultId,
        setCurrentVaultId,
        handleButtonSubmitSave,
        changesTemplate,
        loadingEditor,
        editable,
        setEditable,
        loadingSubmit,
        isLoadingGetVault,
        openModalSearchVault,
        setOpenModalSearchVault,
        handleSearchVaultCallback,
        dataModalConfirm,
        setDataModalConfirm,
        openDrawerTemplateDetails,
        setOpenDrawerTemplateDetails
      }}
    >
      {children}
    </Provider>
  )
}

const useTemplate = () => useContext(store)

export { TemplateContext, useTemplate }
