import { Color } from '@tiptap/extension-color'
import FontFamily from '@tiptap/extension-font-family'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import {
  Field,
  FontSize,
  LineHeight,
  ImageResize
} from '@/utils/editor/extensions'

export const extensions = [
  Field,
  FontFamily,
  FontSize,
  LineHeight,
  Link.configure({
    openOnClick: false,
    autolink: true
  }),
  TextStyle,
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false
    }
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph']
  }),
  Underline,
  Highlight.configure({ multicolor: true }),
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  ImageResize
]
