import { Fragment, useCallback, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useLocalStorage from '@/hooks/useLocalStorage'
import useTranslation from '@/hooks/useTranslation'
import { useThemeWhiteLabel } from '@/providers/ThemeWhiteLabel'
import Button from '@/ui/atoms/Button'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault'
import GroupItemsSidebar from '@/ui/molecules/GroupItemsSidebar'

interface Props {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const Sidebar = ({ isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation('sidebar')
  const { pathname, query, push } = useRouter()
  const [, setCurrentEnvelopeVault] = useLocalStorage<CurrentVaultId>(
    'currentVaultId',
    null
  )
  // const { dataThemeWhiteLabel } = useThemeWhiteLabel()
  // const logoSrc = dataThemeWhiteLabel?.logoLightUrl
  // ? dataThemeWhiteLabel?.logoLightUrl
  // : '/assets/logos/signater/yellow-black-square-with-black-text.png'

  const logoSrc =
    '/assets/logos/signater/yellow-black-square-with-white-text.png'

  const urlCreateEnvelopeButton = useMemo(() => {
    if (pathname === '/vaults/[id]' && query.id) {
      setCurrentEnvelopeVault(query.id as string)
      return `/envelope?origin=sidebar`
    }

    if (window) {
      window.localStorage.removeItem('currentVaultId')
    }
    return '/envelope?origin=sidebar'
  }, [pathname, query.id, setCurrentEnvelopeVault])

  const handleClickLogo = useCallback(() => {
    const isHome = pathname === '/'
    if (isHome) {
      window.location.reload()
    }
    push('/')
  }, [pathname, push])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-75"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-75"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 dark:bg-gray-100" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">{t?.closeSidebar}</span>
                    <XMarkIcon
                      className="w-6 h-6 text-accent-100 dark:text-accent-900"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-col overflow-y-auto bg-gray-900 grow dark:bg-gray-100 ring-1 ring-white/10 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-transparent scrollbar-thumb-rounded-md">
                <div className="flex flex-col px-6 h-auto">
                  <button
                    className="flex h-16 w-[160px] shrink-0 items-center relative cursor-pointer"
                    onClick={handleClickLogo}
                  >
                    <Image
                      fill
                      style={{ objectFit: 'contain' }}
                      src={logoSrc}
                      alt="Signater"
                    />
                  </button>
                </div>
                <nav className="flex flex-col flex-1 overflow-y-auto scrollbar-thin scrollbar-gray-500 scrollbar-track-transparent scrollbar-thumb-rounded-md">
                  <ul
                    role="list"
                    className="flex flex-col flex-1 px-6 pb-4 gap-y-5"
                  >
                    {t?.navigation?.map(({ id, label, items }: any) => (
                      <GroupItemsSidebar key={id} label={label} items={items} />
                    ))}
                  </ul>
                </nav>
                <div className="p-4">
                  <Link href={urlCreateEnvelopeButton}>
                    <Button className="w-full lg:hidden" type="button">
                      {t?.buttonCreateEnvelope}
                    </Button>
                  </Link>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Sidebar
