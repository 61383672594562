import React, { ComponentType, useMemo } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { classNames } from '@/utils/index'
import { useAuth } from '@/providers/Auth'

export type ItemSidebarType = {
  id: string
  label: string
  href?: string
  icon: ComponentType<{ className?: string }>
  hidden?: boolean
  items?: ItemSidebarType[]
}

const ItemSidebar: React.FC<ItemSidebarType> = ({
  label,
  href: hrefProps,
  id,
  items,
  ...props
}) => {
  const { pathname, push } = useRouter()
  const { isAdmin } = useAuth()

  const href = useMemo(() => {
    return hrefProps
  }, [hrefProps])

  const isActive = useMemo(() => {
    if (
      id === 'VAULTS' &&
      (pathname === '/vaults/[id]' || pathname === '/vaults')
    ) {
      return true
    }
    return pathname === href
  }, [href, id, pathname])

  const isActiveChildren = useMemo(() => {
    if (pathname === '/') {
      return false
    }

    return items?.some((item) => item.href?.includes(pathname))
  }, [items, pathname])

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()

      if (pathname === href) {
        window.location.reload()
      } else {
        push(href as string)
      }
    },
    [pathname, href, push]
  )

  const handleClickSubItem = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string) => {
      e.preventDefault()

      if (pathname === href) {
        window.location.reload()
      } else {
        push(href)
      }
    },
    [pathname, push]
  )

  if (!isAdmin && id === 'USERS') {
    return null
  }

  return (
    <li>
      {!items ? (
        <Link
          href={href as string}
          className={classNames(
            isActive
              ? 'bg-gray-800 text-accent-100 dark:bg-gray-200 dark:text-gray-800 font-semibold'
              : 'text-gray-400 dark:text-gray-600 md:hover:text-accent-100 md:hover:bg-gray-800 text-sm font-normal',
            'group flex items-center gap-x-2 sm:gap-x-3 p-2.5 px-5 text-sm leading-6 '
          )}
          onClick={handleClick}
        >
          <props.icon
            className="w-5 h-5 sm:h-6 sm:w-6 shrink-0"
            aria-hidden="true"
          />
          {label}
        </Link>
      ) : (
        <Disclosure as="div" defaultOpen={isActiveChildren}>
          {({ open }) => {
            return (
              <>
                <Disclosure.Button
                  className={classNames(
                    'w-full p-2.5 px-5 flex item-center justify-between text-sm leading-6',
                    isActiveChildren && !open
                      ? 'bg-gray-800 text-accent-100 dark:bg-gray-200 dark:text-gray-800 font-semibold'
                      : 'text-gray-400 dark:text-gray-600 md:hover:text-accent-100 md:hover:bg-gray-800 text-sm font-normal'
                  )}
                >
                  <div className="flex items-center gap-x-2 sm:gap-x-3">
                    <props.icon
                      className="w-5 h-5 sm:h-6 sm:w-6 shrink-0"
                      aria-hidden="true"
                    />
                    {label}
                  </div>
                  <ChevronRightIcon
                    className={classNames(
                      open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                      'ml-auto h-5 w-5 shrink-0'
                    )}
                    aria-hidden="true"
                  />
                </Disclosure.Button>
                <Disclosure.Panel as="ul" className="px-2 mt-1 mb-2">
                  {items.map((subItem) => {
                    const isActiveSubItem = subItem.href === pathname

                    if (subItem.hidden) return

                    return (
                      <li key={subItem.label}>
                        <Disclosure.Button
                          as="a"
                          href={subItem.href}
                          className={classNames(
                            'w-full flex item-center rounded-md py-2 pr-2 pl-6 text-sm leading-6 text-gray-200',
                            isActiveSubItem
                              ? 'bg-gray-800 text-accent-100 dark:bg-gray-200 dark:text-gray-800 font-semibold'
                              : 'text-gray-400 dark:text-gray-600 md:hover:text-accent-100 md:hover:bg-gray-800 text-sm font-normal'
                          )}
                          onClick={(e) =>
                            subItem.href && handleClickSubItem(e, subItem.href)
                          }
                        >
                          {subItem.label}
                        </Disclosure.Button>
                      </li>
                    )
                  })}
                </Disclosure.Panel>
              </>
            )
          }}
        </Disclosure>
      )}
    </li>
  )
}

export default ItemSidebar
