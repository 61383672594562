import { Node, mergeAttributes } from '@tiptap/core'
import { SuggestionOptions } from '@tiptap/suggestion'

export type FieldOptions = {
  HTMLAttributes: Record<string, any>
  renderLabel: (props: { options: FieldOptions; node: any }) => string
  suggestion: Omit<SuggestionOptions, 'editor'>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    field: {
      /**
       * Set the field
       */
      setField: (field: string) => ReturnType
      /**
       * Update the field value
       */
      updateFieldValue: (field: string, value: string) => ReturnType
    }
  }
}

export const Field = Node.create({
  name: 'field',
  group: 'inline',
  inline: true,
  addAttributes() {
    return {
      alias: { default: null },
      value: { default: null }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'span[data-field="true"]',
        getAttrs: (element) => {
          if (element instanceof HTMLElement) {
            return {
              alias: element.getAttribute('alias'),
              value: element.textContent
            }
          }
          return { alias: null, value: null }
        }
      }
    ]
  },
  renderHTML({ node }) {
    const { alias, value } = node.attrs
    return [
      'span',
      mergeAttributes({
        'data-field': 'true',
        class: value === alias || !value ? 'field-template' : '',
        alias
      }),
      value || alias
    ]
  },
  addCommands() {
    return {
      setField:
        (alias: string) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { alias }
          })
        },
      updateFieldValue:
        (alias, value) =>
        ({ tr, state }) => {
          const { doc } = state
          let transaction = tr

          doc.descendants((node, pos) => {
            if (node.type.name === this.name && node.attrs.alias === alias) {
              const updatedAttrs = { ...node.attrs, value }
              transaction = transaction.setNodeMarkup(pos, null, updatedAttrs)
            }
          })

          return transaction as any
        }
    }
  }
})
