import { useAuth } from '@/providers/Auth'
import ItemSidebar, { ItemSidebarType } from '@/ui/molecules/ItemSidebar'

interface Props {
  label: string
  items: ItemSidebarType[]
}

const GroupItemsSidebar = ({ label, items }: Props) => {
  const { isAdmin } = useAuth()

  return (
    <li>
      {label !== '' && (
        <div className="mb-2 text-xs font-normal leading-6 text-gray-400 uppercase dark:text-gray-600">
          {label}
        </div>
      )}
      <ul role="list" className="-mx-6">
        {items?.map((item) => {
          if (
            (item.id === 'BILLING' ||
              item.id === 'USERS' ||
              item.id === 'MY_ACCOUNT') &&
            !isAdmin
          ) {
            return
          }
          return <ItemSidebar key={item.id} {...item} />
        })}
      </ul>
    </li>
  )
}

export default GroupItemsSidebar
