import React, { useMemo } from 'react'
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import usePersistedTheme from '@/hooks/usePersistedTheme'
import useTranslation from '@/hooks/useTranslation'
import { useAuth } from '@/providers/Auth'
import Button from '@/ui/atoms/Button'
import SandboxAlert from '@/ui/molecules/SandboxAlert'
import HeaderOptionGroup from '@/ui/organisms/HeaderOptionGroup'

export type Props = {
  mode: 'createOrEdit' | 'view' | 'review'
  onGoBack?: () => void
  showSandboxAlertBottom?: boolean
}

const HeaderEnvelope: React.FC<Props> = ({
  mode,
  onGoBack,
  showSandboxAlertBottom
}) => {
  const { t, isReady } = useTranslation('header')
  const { pathname } = useRouter()
  const { isSandbox } = useAuth()
  const { theme } = usePersistedTheme()

  const logoSrc =
    theme === 'dark'
      ? '/assets/logos/signater/yellow-black-square-with-white-text.png'
      : '/assets/logos/signater/yellow-black-square-with-black-text.png'

  const buttonGoBack = useMemo(() => {
    return pathname === '/envelope/[id]/view' ? t?.goBack : t?.cancel
  }, [pathname, t?.cancel, t?.goBack])

  return (
    <div className="sticky top-0 z-40 bg-white dark:bg-black">
      {isSandbox && mode === 'createOrEdit' && (
        <SandboxAlert>{t?.sandboxMode}</SandboxAlert>
      )}
      <div className="relative flex-grow w-full border-b border-gray-200 shadow-sm flex items-center h-16">
        <div className="flex items-center w-full h-full px-4 shrink-0 container-main gap-x-4 sm:gap-x-6">
          <div className="flex items-center self-stretch justify-end flex-1 w-full gap-x-4 lg:gap-x-6 lg:justify-between">
            <div className="flex items-center self-stretch justify-start flex-1 w-full gap-x-4">
              {onGoBack ? (
                <Button
                  variant="link"
                  className="items-center hidden gap-2 text-sm font-normal lg:flex hover:no-underline"
                  onClick={onGoBack}
                  showSkeleton={!isReady}
                >
                  <ArrowSmallLeftIcon
                    className="w-4 h-4 fill-current"
                    aria-hidden="true"
                  />
                  {buttonGoBack}
                </Button>
              ) : (
                <Link href="/">
                  <div className="pt-2 cursor-pointer">
                    <Image
                      width={160}
                      height={32}
                      src={logoSrc}
                      alt="Signater"
                    />
                  </div>
                </Link>
              )}
            </div>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <HeaderOptionGroup />
            </div>
          </div>
        </div>
      </div>
      {showSandboxAlertBottom && mode !== 'createOrEdit' && (
        <SandboxAlert classNameText="normal-case">
          {t?.sandboxModePublicViewEnvelope}
        </SandboxAlert>
      )}
    </div>
  )
}

export default HeaderEnvelope
