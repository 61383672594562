import React, { useCallback } from 'react'
import {
  Bars3BottomLeftIcon,
  ArrowSmallLeftIcon
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { useAuth } from '@/providers/Auth'
import { useTemplate } from '@/providers/Template'
import HeaderOptionGroup from '../HeaderOptionGroup'
import Button from '@/ui/atoms/Button'
import SandboxAlert from '@/ui/molecules/SandboxAlert'

const HeaderTemplate = () => {
  const { t } = useTranslation('header')
  const { openDrawerTemplateDetails, setOpenDrawerTemplateDetails } =
    useTemplate()

  const { isSandbox } = useAuth()
  const { back } = useRouter()

  const handleClickBurger = useCallback(() => {
    setOpenDrawerTemplateDetails(!openDrawerTemplateDetails)
  }, [openDrawerTemplateDetails, setOpenDrawerTemplateDetails])

  return (
    <div className="sticky top-0 z-40 bg-white dark:bg-black">
      {isSandbox && <SandboxAlert>{t?.sandboxMode}</SandboxAlert>}
      <div className="flex items-center w-full h-16 px-4 border-b border-gray-200 shadow-sm shrink-0 gap-x-4 text-accent-100 sm:gap-x-6 sm:px-6 lg:px-8">
        <div className="flex items-center self-stretch justify-start flex-1 w-full gap-x-4">
          <div className="flex flex-col xl:hidden">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700"
              onClick={handleClickBurger}
            >
              <span className="sr-only">{t?.openSidebar}</span>
              <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
          <Button
            variant="link"
            className="items-center hidden gap-2 text-sm font-normal lg:flex hover:no-underline"
            onClick={() => {
              if (window) {
                window.localStorage.removeItem('currentVaultId')
              }
              back()
            }}
          >
            <ArrowSmallLeftIcon
              className="w-5 h-5 fill-current"
              aria-hidden="true"
            />
            {t?.cancel}
          </Button>
        </div>
        <div className="flex items-center gap-x-4">
          <HeaderOptionGroup />
        </div>
      </div>
    </div>
  )
}

export default HeaderTemplate
